import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { ModuleWrapper } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import { SanityImage } from "@components/sanity/sanityImage";
import useEmblaCarousel from "embla-carousel-react";
import cx from "classnames";

import Vector from "@svg/vector.svg";
import VectorRed from "@svg/vector-red.svg";
import ChevronPrev from "@svg/chevron-prev.svg";
import ChevronNext from "@svg/chevron-next.svg";

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      " transition-all ease-in-out duration-300 mr-1 w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10 ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <ChevronPrev className="m-auto" />
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      "transition-all ease-in-out duration-300 ml-4 w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10 ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <ChevronNext className="m-auto " />
  </button>
);

const AccordianItem = ({
  header,
  address,
  capacity,
  locationImages,
  active,
  setActive,
  _key,
}) => {
  const isActive = active === _key;
  const handleClick = () => (!isActive ? setActive(_key) : setActive(null));

  const [currentIndex, setCurrentIndex] = useState(0);
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: true,
    loop: true,
  });

  const onSwipe = React.useCallback(() => {
    if (!embla) return;
    setCurrentIndex(embla.selectedScrollSnap());
  }, [embla]);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
    setCurrentIndex(embla.selectedScrollSnap());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    embla.on("select", onSelect);
  }, [embla, onSelect]);

  React.useEffect(() => {
    if (!embla) return;
    onSwipe();
    embla.on("select", onSwipe);
  }, [embla, onSwipe]);

  return (
    <div className="">
      <div
        className={cx(
          "flex justify-between items-center cursor-pointer border-b  pb-6",
          {
            "border-gray-300": !isActive,
            "border-red": isActive,
          }
        )}
        onClick={handleClick}
      >
        <h1
          className={cx("blockH3 ", {
            "text-dark-grey": !isActive,
            "text-red": isActive,
          })}
        >
          {header}
        </h1>
        <motion.div animate={{ rotate: !isActive ? 0 : 180 }}>
          {isActive ? (
            <VectorRed className="w-9" />
          ) : (
            <Vector className="w-9 " />
          )}
        </motion.div>
      </div>

      <div className=" w-full h-1 pb-8"></div>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="overflow-hidden lg:grid grid-cols-12"
            transition={{
              duration: 0.5,
            }}
          >
            <div className="blockH3 col-start-1 col-end-5 row-start-1 row-end-3 self-center mb-16 lg:mb-0">
              <div className="pb-6 lg:pb-12">
                <h1 className="blockH3 text-red mb-3">Address</h1>
                <PortableTextBlock className="text-dark-grey" text={address} />
              </div>
              <div className="pt-6 lg:pt-12 border-t border-gray-300">
                <h1 className="blockH3 text-red mb-3">Capacity</h1>{" "}
                <p className="blockH3  text-dark-grey ">{capacity}</p>
              </div>
            </div>

            <div className="col-start-6 col-end-13 row-start-1 row-end-3 bg-white rounded-3xl overflow-hidden">
              <div ref={viewportRef} className="w-full overflow-hidden">
                <div className="flex">
                  {locationImages?.map((location, i) => (
                    <div
                      className="aspect-w-16 aspect-h-10 relative w-full "
                      style={{ flex: "0 0 auto" }}
                    >
                      <SanityImage
                        className="absolute top-0 left-0 object-cover w-full h-full"
                        image={location.textImage}
                        key={i}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className=" flex justify-between mt-10 mx-6 ">
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={currentIndex}
                    className="blockH7  "
                  >
                    <p className="text-sm lg:blockH7 pr-10 lg:pr-28 text-dark-grey">
                      {locationImages[currentIndex].imageText}
                    </p>
                  </motion.div>
                </AnimatePresence>
                <div className=" flex justify-end  pb-10">
                  <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                  <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                </div>
              </div>
            </div>
            <div className="col-start-1 col-span-full w-full h-16"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const LocationBlock = ({ config, mainHeader, items }) => {
  const [active, setActive] = useState(null);

  return (
    <ModuleWrapper {...config}>
      <div className="px-gutter">
        <h1 className="blockH2 mb-20 text-red">{mainHeader}</h1>
        {items?.map((i) => (
          <AccordianItem {...i} active={active} setActive={setActive} />
        ))}
      </div>
    </ModuleWrapper>
  );
};

export default LocationBlock;
